import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import { getFeaturedListings } from 'api/listings';
import ListingCard from '../../components/ListingCard';

const FeaturedSection = ({ amountPropMob = 4 }) => {
  const { t } = useTranslation();
  const [featuredListings, setFeaturedListings] = useState<any[]>([]);
  const layout = useLayout();

  useEffect(() => {
    const fetchFeaturedListings = async () => {
      try {
        const data = await getFeaturedListings();
        setFeaturedListings(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Can't fetch featured listings`);
      }
    };

    fetchFeaturedListings();
  }, []);

  const listingItems =
    layout === 'desktop' ? featuredListings.slice(0, 8) : featuredListings.slice(0, amountPropMob);

  return (
    <StyledPropertiesSection className="properties-section">
      <div className="properties-section__wrapper">
        <h4 className="properties-section__title">{t('home_page.featured_properties')}</h4>
        <div className="properties-list" id="lazy-scroll-container'">
          {listingItems.map(item => (
            <LazyLoad
              key={item.id}
              height={276}
              scrollContainer={document.querySelector('#lazy-scroll-container') || undefined}>
              <ListingCard data={item} />
            </LazyLoad>
          ))}
        </div>
      </div>
    </StyledPropertiesSection>
  );
};

const StyledPropertiesSection = styled.div`
  padding: 28px 16px 48px;
  display: flex;
  justify-content: center;
  .properties-section {
    &__wrapper {
      max-width: 1334px;
      flex: 1;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.2;
      color: ${props => props.theme.colors.black};
      margin: 0 0 28px;
    }
  }
  .properties-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 322px));
    grid-gap: 16px;
    max-width: 1334px;
    .property-card {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .properties-list {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 28px 16px;
    .properties-list {
      grid-template-columns: 1fr;
    }
    .properties-section {
      &__title {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }
  }
`;

export default FeaturedSection;
